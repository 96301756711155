import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from "react";
import Slider from "react-slick";
import classNames from "classnames";
import memoize from "utils/memoize";
import RHImage from "@RHCommerceDev/rh-image-component";
import NextArrow from "@RHCommerceDev/component-rh-carousel/NextArrow";
import PreviousArrow from "@RHCommerceDev/component-rh-carousel/PreviousArrow";
import DotsCarousel from "@RHCommerceDev/component-product-grid/ImageCarousel/DotsCarousel";
import PlayIcon from "icons/PlayIcon";
import { IMAGE_ASPECT_RATIO } from "utils/constants";

interface ThumbnailCarouselProps {
  icon?: string;
  images?: any[];
  slide?: any;
  currentIndex?: number;
  onSelectSlide?: (slide: PromoDisplay) => void;
  carouselProps?: any;
  desktopMatchesQuery?: boolean;
  noCondiments?: boolean;
  carouselStyle?: any;
  extraOperations?: {
    isArrowsClicked?: Boolean;
    setIsArrowsClicked?: Dispatch<SetStateAction<Boolean>>;
  };
  isPdp?: boolean;
}

const ThumbnailCarousel: FC<ThumbnailCarouselProps> = ({
  images = [],
  slide,
  icon = "no",
  currentIndex = 0,
  onSelectSlide,
  carouselProps,
  desktopMatchesQuery,
  carouselStyle,
  noCondiments = true,
  extraOperations,
  isPdp = false
}) => {
  const sliderRef = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState(currentIndex);
  const slidesToShow = carouselProps?.slidesToShow || 5;

  const settings = {
    ...carouselProps,
    dots: false,
    speed: 500,
    slidesToShow,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setActiveIndex(newIndex);
    },
    afterChange: (newIndex: number) => {
      setActiveIndex(newIndex);
    },
    ...(desktopMatchesQuery
      ? {
          nextArrow: (
            <NextArrow
              carouselArrowGridStyles={{
                right: `${carouselStyle?.arrowStyle}`
              }}
              nextArrowAccessibilityProps={{
                style: {
                  opacity: activeIndex >= images.length - slidesToShow ? 0.2 : 1
                }
              }}
              icon={icon}
            />
          ),
          prevArrow: (
            <PreviousArrow
              carouselArrowGridStyles={{
                left: `${carouselStyle?.arrowStyle}`
              }}
              prevArrowAccessibilityProps={{
                style: {
                  opacity: activeIndex === 0 ? 0.2 : 1
                }
              }}
              icon={icon}
            />
          )
        }
      : {
          arrows: false
        })
  };

  /* 
   if we restrict infinite scroll we will be having 3 issues 
   1. click on 6th or 7th image and then click on next button then blank space is getting added
   2. click on 2nd last or 3rd last image and then click on next arrow last image is not showing up
   upon solving above 2 issues hero image and thumbnail image sync is breaking 
   3. when on 1st image and clicking on prev arrow for the hero image the thumbnail image scrolling to the end of the carousel */
  useEffect(() => {
    if (isPdp) {
      if (currentIndex >= 0 && extraOperations?.isArrowsClicked) {
        return sliderRef.current.slickGoTo(currentIndex);
      }
      let totalSlides = images.length;
      // Scroll to currentIndex initially
      const currentSlide = activeIndex;
      const remainingSlides = totalSlides - (currentSlide + 5);
      const slidesToScroll =
        remainingSlides <= 0 ? totalSlides : remainingSlides >= 5 ? 5 : 1;

      if (sliderRef.current) {
        if (currentSlide > 7 && currentSlide < images.length) {
          const imagesLeftToScroll = images.length - 1 - currentSlide;
          sliderRef.current.slickGoTo(currentSlide + (imagesLeftToScroll - 1));
        } else {
          sliderRef.current.slickGoTo(
            currentSlide !== 0
              ? currentSlide + slidesToScroll - 1
              : currentSlide
          );
        }
      }
    } else {
      return sliderRef.current.slickGoTo(currentIndex);
    }
  }, [activeIndex, currentIndex, extraOperations, images.length, isPdp]);

  return (
    <div onClick={() => extraOperations?.setIsArrowsClicked(false)}>
      <Slider ref={sliderRef} {...settings} className=" [&>div>div]:float-left">
        {images.map((subItem, i) => (
          <div key={subItem.id} data-testid="carousel-sbr-image">
            <div
              className={classNames(
                "relative box-border overflow-auto overflow-y-hidden",
                "m-[0.8rem] h-auto",
                {
                  "border-white border-[3px]": slide?.id === subItem?.id,
                  "outline outline-[1px] outline-black":
                    slide?.id === subItem?.id
                }
              )}
              id="thumbnail-wrapper"
            >
              <RHImage
                src={subItem.imageUrl || ""}
                alt={subItem.name || ""}
                className={`h-full !object-contain cursor-pointer ${carouselStyle?.imageStyle}`}
                containerProps={{
                  className: `"!block max-w-[350px] h-full bg-[#F9F7F4]" ${carouselStyle?.containerProps}`
                }}
                style={{
                  aspectRatio: `${IMAGE_ASPECT_RATIO["thumbnail"]}`
                }}
                onClick={() => {
                  extraOperations?.setIsArrowsClicked(false);
                  onSelectSlide?.(subItem);
                }}
                data-testid="rh-image"
              />
              {Boolean(subItem?.video) && (
                <PlayIcon className="absolute left-0 right-0 top-0 bottom-0 m-auto text-white pointer-events-none !text-[40px]" />
              )}
            </div>
            {desktopMatchesQuery && noCondiments && (
              <div className="leading-none -mt-1 pl-2 text-gray-600">
                {i + 1}
              </div>
            )}
          </div>
        ))}
      </Slider>
      {!desktopMatchesQuery && noCondiments && (
        <div className="thumbnail-dots-Carousel grid justify-center">
          <DotsCarousel
            length={images?.length}
            maxVisibleIndicators={5}
            current={activeIndex}
          />
        </div>
      )}
    </div>
  );
};

export default memoize(ThumbnailCarousel);
